import { Component, Inject } from '@angular/core';
//import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// import esJson from '../../../../assets/i18n/es.json';
// import enJson from '../../../../assets/i18n/en.json';

@Component({
    selector: 'app-cancel-order-dialog',
    templateUrl: './cancel-order-dialog.component.html',
    styleUrls: ['./cancel-order-dialog.component.scss'],
    standalone: false
})
export class CancelOrderDialogComponent {
  langJson: any;
  yes: string;
  cancel: string;
  text = '';

  constructor(
    public dialogRef: MatDialogRef<CancelOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) {
      this.yes = (localStorage.getItem('lang') === 'es') ? 'Cancelar pedido' : 'Cancel Order';
      this.cancel = (localStorage.getItem('lang') === 'es') ? 'Volver' : 'Go back';
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onContinue(): void {
    if(this.text === '' || this.text === undefined || this.text === null){
      this.dialogRef.close(true);
    }else{
      this.dialogRef.close(this.text);
    }
  }
}
